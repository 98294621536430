// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-holiday-lets-index-js": () => import("./../../../src/pages/holiday-lets/index.js" /* webpackChunkName: "component---src-pages-holiday-lets-index-js" */),
  "component---src-pages-off-plan-projects-for-sale-index-js": () => import("./../../../src/pages/off-plan-projects/for-sale/index.js" /* webpackChunkName: "component---src-pages-off-plan-projects-for-sale-index-js" */),
  "component---src-pages-off-plan-property-for-sale-index-js": () => import("./../../../src/pages/off-plan-property/for-sale/index.js" /* webpackChunkName: "component---src-pages-off-plan-property-for-sale-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-property-for-sale-index-js": () => import("./../../../src/pages/property/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-for-sale-index-js" */),
  "component---src-pages-property-to-rent-index-js": () => import("./../../../src/pages/property/to-rent/index.js" /* webpackChunkName: "component---src-pages-property-to-rent-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-area-guide-details-template-js": () => import("./../../../src/templates/area-guide-details-template.js" /* webpackChunkName: "component---src-templates-area-guide-details-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-news-details-template-js": () => import("./../../../src/templates/news-details-template.js" /* webpackChunkName: "component---src-templates-news-details-template-js" */),
  "component---src-templates-off-plan-property-details-js": () => import("./../../../src/templates/off-plan-property-details.js" /* webpackChunkName: "component---src-templates-off-plan-property-details-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-team-details-template-js": () => import("./../../../src/templates/team-details-template.js" /* webpackChunkName: "component---src-templates-team-details-template-js" */)
}

